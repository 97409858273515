import request from './request'

// 按需导出每个请求，咱们称这种请求为每个api


// export const HomeApi = () => request.get('/index/index')

//登录
export const Gologin = (params) => request.post('/api.php/demo/login/login', params)
// 退出登录
export const loginOut = (params) => request.post('/api.php/demo/login/loginOut', params)
// 主页
export const indextotal = (params) => request.post('/api.php/demo/index/index', params)

// 系统配置
export const getSetting = (params) => request.post('/api.php/demo/setting/getSetting', params)
export const website = (params) => request.post('/api.php/demo/setting/website', params)



// 增加场地信息
export const addressAdd = (params) => request.post('/api.php/demo/address/add', params)
// 修改场地信息
export const addressEdit = (params) => request.post('/api.php/demo/address/edit', params)
// 删除场地信息
export const addressDel = (params) => request.post('/api.php/demo/address/del', params)
// 查询场地信息   
export const addressList = (params) => request.post('/api.php/demo/address/addressList', params)
// 查询场地信息
export const addrCount = (params) => request.post('/api.php/demo/address/addrCount', params)
// 场地统计
export const addressCount = (params) => request.post('/api.php/demo/address/addressCount', params)
// 账单查询
export const orderStatus = (params) => request.post('/api.php/demo/order/orderStatus', params)
// 加载图片
export const reChangeQrCode = (params) => request.post('/api.php/demo/address_access/reChangeQrCode', params)

// 添加设备
export const deviceAdd = (params) => request.post('/api.php/demo/device/add', params)
// 查询设备
export const deviceList = (params) => request.post('/api.php/demo/device/deviceList', params)
// 查询删除
export const deviceDel = (params) => request.post('/api.php/demo/device/del', params)
// 查询修改
export const deviceEdit = (params) => request.post('/api.php/demo/device/edit', params)
// 端口设备列表
export const devicePortList = (params) => request.post('/api.php/demo/device/devicePortList', params)
//服务器远程禁用/解禁端口
export const switchDevicePort = (params) => request.post('/api.php/demo/device/switchDevicePort', params)
//停止充电
export const closeCharge = (params) => request.post('/api.php/demo/device/closeCharge', params)
//设备统计
export const deviceCount = (params) => request.post('/api.php/demo/device/deviceCount', params)

// 套餐添加
export const packageAdd = (params) => request.post('/api.php/demo/package/add', params)
// 套餐查询
export const packageList = (params) => request.post('/api.php/demo/package/packageList', params)
// 套餐查询单条
export const packageFirst = (params) => request.post('/api.php/demo/package/first', params)
// 套餐修改
export const packageEdit = (params) => request.post('/api.php/demo/package/edit', params)
// 套餐删除
export const packageDel = (params) => request.post('/api.php/demo/package/del', params)

// 管理员查询
export const adminList = (params) => request.post('/api.php/demo/admin/adminList', params)
// 管理员增加
export const adminAdd = (params) => request.post('/api.php/demo/admin/add', params)
// 管理员删除
export const adminDel = (params) => request.post('/api.php/demo/admin/del', params)
// 管理员修改
export const adminEdit = (params) => request.post('/api.php/demo/admin/edit', params)

// 物业管理查询
export const propertyList = (params) => request.post('/api.php/demo/address_manager/addressManagerList', params)
// 物业管理幸喜查询
export const propertyFirst = (params) => request.post('/api.php/demo/address_manager/first', params)
// 物业管理增加
export const propertyAdd = (params) => request.post('/api.php/demo/address_manager/create', params)
// // 物业管理删除
export const propertyDel = (params) => request.post('/api.php/demo/address_manager/delete', params)
// // 物业管理修改
export const propertyEdit = (params) => request.post('/api.php/demo/address_manager/upd', params)
// // 物业管理是否使用
export const updateStatus = (params) => request.post('/api.php/demo/address_manager/updateStatus', params)

// 添加场地类型
export const addressType = (params) => request.post('/api.php/demo/address_type/add', params)
// 修改场地类型
export const addressTypeEdit = (params) => request.post('/api.php/demo/address_type/edit', params)
//删除场地类型
export const addressTypeDel = (params) => request.post('/api.php/demo/address_type/del', params)

//用户审核列表和查询多条
export const vehicleList = (params) => request.post('/api.php/demo/vehicle/vehicleList', params)
// //用户购买记录
export const userOrderCard = (params) => request.post('/api.php/demo/index/userOrderCard', params)
// //用户充电记录
export const chargeRecords = (params) => request.post('/api.php/demo/index/chargeRecords', params)
// //用户开门记录
export const openTheDoorRecord = (params) => request.post('/api.php/demo/index/openTheDoorRecord', params)
// //用户开门记录
export const addressList1 = (params) => request.post('/api.php/demo/index/addressList', params)
//查询单条数
export const vehicleFirst = (params) => request.post('/api.php/demo/vehicle/first', params)
//车辆信息审核
export const vehicleEdit = (params) => request.post('/api.php/demo/vehicle/edit', params)
//用户充值记录
// export const vehicleEdit = (params) => request.post('/api.php/demo/vehicle/vehicleList', params)

//门禁场地多条
export const accessList = (params) => request.post('/api.php/demo/address_access/accessList', params)
//门禁修改
export const accessedit = (params) => request.post('/api.php/demo/address_access/edit', params)
//门禁删除
export const accessDel = (params) => request.post('/api.php/demo/address_access/del', params)
//门禁增加
export const accessAdd = (params) => request.post('/api.php/demo/address_access/add', params)
//门禁状态查看
export const getStatus = (params) => request.post('/api.php/demo/address_access/getStatus', params)

//订单查询
export const orderOrderList = (params) => request.post('/api.php/demo/order/orderList', params)
//订单退款
export const orderRefund = (params) => request.post('/api.php/demo/order/refund', params)

//故障信息
export const errorLog = (params) => request.post('/api.php/demo/device_error_log/errList', params)
//故障问题已解决
export const errortongguo = (params) => request.post('/api.php/demo/device_error_log/updateStatus', params)


//跳转组添加
export const addjumpgroup = (params) => request.post('/api.php/demo/jump_group/add', params)
//跳转组liebia
export const jumpGroupList = (params) => request.post('/api.php/demo/jump_group/jumpGroupList', params)
//跳转组修改
export const jumpGroupedit = (params) => request.post('/api.php/demo/jump_group/edit', params)
//跳转组删除
export const jumpGroupedel = (params) => request.post('/api.php/demo/jump_group/del', params)
//跳转组快速编辑
export const jumpGroupeupd = (params) => request.post('/api.php/demo/jump_group/upd', params)
// 跳转组所有
export const jumpGroupAll = (params) => request.post('/api.php/demo/jump_group/jumpGroupAll', params)

// 添加卡
export const cardAdd = (params) => request.post('/api.php/demo/card/cardAdd', params)
// 列表卡
export const cardList = (params) => request.post('/api.php/demo/card/cardList', params)
// 全部套餐
export const packageAll = (params) => request.post('/api.php/demo/package/packageAll', params)
//修改卡
export const cardSave = (params) => request.post('/api.php/demo/card/cardSave', params)
//添加套餐
export const addPackage = (params) => request.post('/api.php/demo/card/addPackage', params)

// ===============

// 测试
export const test = (params) => request.post('/api.php/demo/card/car', params)
// 切换展示开关
export const changeSwitch = (params) => request.post('/api.php/demo/card/car', params)
// 免费门禁
export const freeChange = (params) => request.post('/api.php/demo/vehicle/vedit', params)
// 免费充电开关
export const freeChange2 = (params) => request.post('/api.php/demo/vehicle/vedit2', params)
// 套餐列表
export const packageList2 = (params) => request.post('/api.php/demo/vehicle/tclist', params)
// 修改套餐
export const editPackage = (params) => request.post('/api.php/demo/vehicle/tcedit', params)
// 修改用户类型
export const changeTypes = (params) => request.post('/api.php/demo/vehicle/xedit', params)
// 数据导出接口
export const expostData = (params) => request.post('/api.php/demo/vehicle/vuser', params)
// 逾期群发短信
export const sendmsg = (params) => request.post('/api.php/home/Jobqueue/actionWithHelloJob', params)
// 逾期群发短信回调列表
export const sendMsgList = (params) => request.post('/api.php/home/Jobqueue/lists', params)
// 订单提醒列表
export const orderLists = (params) => request.post('/api.php/demo/index/cars', params)
// 修改提醒数据
export const editOrderLists = (params) => request.post('/api.php/demo/index/editcar', params)
// 删除卡
export const deleteCard = (params) => request.post('/api.php/demo/card/carddel', params)

// 主页查询
export const srchses = (params) => request.post('/api.php/demo/index/ortj', params)

// 新充电接口
// 挡位列表
export const dwList = (params) => request.post('/api.php/demo/index/dwslist', params)
// 添加挡位
export const dwAdd = (params) => request.post('/api.php/demo/index/dwsadd', params)
// 编辑挡位
export const dwEdit = (params) => request.post('/api.php/demo/index/dwsedit', params)
// 移除挡位
export const dwDelete = (params) => request.post('/api.php/demo/index/dwsdel', params)
// 充值列表
export const dwChargin = (params) => request.post('/api.php/demo/order/deorderlist', params)
// 退款、
export const dwRefund = (params) => request.post('/api.php/demo/order/refund2', params)

// 订单统计
export const ordersd = (params) => request.post('/api.php/demo/index/ordersd', params)

// 新充电接口退款
export const dwTk = (params) => request.post('/api.php/demo/login/yuetk', params)
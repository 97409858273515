import { createStore } from 'vuex'

export default createStore({
  state: {
    page:1,
    pagepage:10,
    pagination:true,
   
  },
  mutations: {
    pageChange(state){
      state.page=1;
    },
    pagination(state,info){
      state.pagination=info?true:false
    },
    paginationpage(state){
      state.pagepage=10
    }
  },
  actions: {
  },
  modules: {
  }
})


<template>
  <div class="home_page">
    <div class="left">

      <el-menu :default-active="activemenus" class="el-menu-vertical-demo" @select="handleOpen" :collapse="isCollapse"
        background-color=" #253964" text-color="#fff" active-text-color="#F3C800" :unique-opened="true">
        <div class="logo">
          <img :src="imgLogo" alt />
          <span style="color:'#fff" v-if="isCollapse == false">充电管理后台</span>
        </div>
        <el-menu-item index="1" @click="$router.push('/index')">
          <i class="iconfont icon-geren1"></i>
          <template #title>主页</template>
        </el-menu-item>
        <el-submenu index="2">
          <template #title>
            <i class="iconfont icon-shouyi"></i>
            <span>收益</span>
          </template>
          <el-menu-item index="2-1" @click="$router.push('/equipmentStatis')">
            <template #title>设备统计</template>
          </el-menu-item>
          <el-menu-item index="2-2" @click="$router.push('/changStatis')">
            <template #title>场地统计</template>
          </el-menu-item>
          <el-menu-item index="2-4" @click="$router.push('/billQuery')">
            <template #title>账单查询</template>
          </el-menu-item>
          <el-menu-item index="2-5" @click="$router.push('/taocandimens')">
            <template #title>套餐分析</template>
          </el-menu-item>
          <el-menu-item index="2-6" @click="$router.push('/orderStatistics')">
            <template #title>订单统计</template>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="3">
          <template #title>
            <i class="iconfont icon-guanli"></i>
            <span>设备管理</span>
          </template>
          <el-menu-item index="3-1" @click="$router.push('/equipmentManger')">
            <template #title>设备管理</template>
          </el-menu-item>
          <el-menu-item index="3-3" @click="$router.push('/equipmentGuzh')">
            <template #title>设备故障信息</template>
          </el-menu-item>
          <el-menu-item index="3-4" @click="$router.push('/cartManger')">
            <template #title>卡管理</template>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="4">
          <template #title>
            <i class="iconfont icon-dingwei"></i>
            <span>场地管理</span>
          </template>
          <el-menu-item index="4-1" @click="$router.push('/areaManger')">
            <template #title>场地管理</template>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="5" v-if="role != 5">
          <template #title>
            <i class="iconfont icon-yonghu"></i>
            <span>用户管理</span>
          </template>
          <el-menu-item index="5-1" @click="$router.push('/userManger')">
            <template #title>用户列表</template>
          </el-menu-item>
        </el-submenu>
        <el-submenu index="7">
          <template #title>
            <i class="iconfont icon-yunying"></i>
            <span>运营功能</span>
          </template>
          <el-menu-item index="7-1" @click="$router.push('/orderSearch')">
            <template #title>订单查询</template>
          </el-menu-item>

          <el-menu-item index="7-2" @click="$router.push('/openrecord')">
            <template #title>开门记录</template>
          </el-menu-item>
          <el-menu-item index="7-4" @click="$router.push('/taocanManger')" v-if="role != 5">
            <template #title>套餐管理</template>
          </el-menu-item>
        </el-submenu>


        <el-submenu index="11">
          <template #title>
            <i class="iconfont icon-guanli"></i>
            <span>新充电功能</span>
          </template>
          <el-menu-item index="11-1" @click="$router.push('/gearManager')">
            <template #title>挡位管理</template>
          </el-menu-item>

          <el-menu-item index="11-2" @click="$router.push('/newchargeList')">
            <template #title>充值记录

            </template>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="8" @click="$router.push('/admin')" v-if="role != 5">
          <i class="iconfont icon-dingwei"></i>
          <template #title>管理员</template>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right">
      <div class="top">
        <div class="img"></div>
        <text>{{ userinfo }}</text>
        <el-popover placement="bottom" :width="160" v-model:visible="visible">
          <p style="line-height:60px;text-align:center">是否要退出登录？</p>
          <div style="text-align: right; margin: 0;display:flex; justify-content: space-between;">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="loginOut()">确定</el-button>
          </div>
          <template #reference>
            <i class="iconfont icon-xiangxia" @click="visible = !visible"></i>
          </template>
        </el-popover>
      </div>
      <div class="router-view">
        <router-view :pagepage="pagepage" :page="page" @pagetotalClick="pagetotalClick"
          @isshowpageclick="isshowpageclick" />
      </div>
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="currentPage4" layout="total,sizes, prev, pager, next, jumper" :total="pagetotal"
          :page-sizes="[10, 15, 20, 25]" v-if="$store.state.pagination"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { loginOut } from "../request/api";
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      isCollapse: false,
      visible: false,
      role: '',
      userinfo: "",
      currentPage4: 1,
      page: 1,
      pagetotal: 0,
      isshowpage: true,
      pagepage: 10,
      activemenus: "1",
      imgLogo: require("../assets/logo2.png"),
      screenWidth: null,
      screenWidth1: null,
    };
  },
  watch: {
    screenWidth1: {
      handler: function (val) {
        if (val < this.screenWidth * 0.7) {
          this.isCollapse = true
        } else {
          this.isCollapse = false
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    console.log(this.screenWidth, 'dsf')
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth1 = document.body.clientWidth
      })()
    }
  },
  methods: {
    handleOpen(e) {
      this.currentPage4 = this.page
      localStorage.setItem('activemenus', e)
      this.activemenus = e
    },
    // isshowpageclick(val) {
    //   this.isshowpage =val?false:true;
    // },          
    handleSizeChange(val) {
      this.pagepage = val;
    },
    pagetotalClick(val) {
      this.pagetotal = val;
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    // 退出登录
    loginOut() {
      loginOut().then(res => {
        if (res.code == 200) {
          ElMessage.success({
            message: "退出成功",
            type: "success"
          });
          localStorage.removeItem("token");
          localStorage.removeItem("userinfo");
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        }
      });
    }
  },
  created() {
    // this.$store.state.ishowpage
    this.activemenus = localStorage.getItem('activemenus') ? localStorage.getItem('activemenus') : 1
    this.role = localStorage.getItem('role')
    this.userinfo = localStorage.getItem("userinfo");
    // this.ishowpage=this.$store.state.pagination
  }
};
</script>
<style lang="less" scoped>
ul {
  border-right: none
}

/deep/.el-table::before {
  width: 0;
}

/deep/.cell {
  text-align: center;
}

.block {
  margin-top: -60px;
  margin-left: 30px;
}

.right {
  width: 89%;
  margin-left: 1%;
  height: 100vh;

  .router-view {
    width: 100%;
    height: 93vh;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: scroll;
  }

  flex: 1;

  .top {
    height: 6vh;
    padding: 20px 0;
    box-sizing: border-box;
    line-height: 50px;
    width: 100%;
    text-align: right;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .img {
      margin-right: 15px;
      width: 30px;
      height: 30px;
      background-color: #000;
      border-radius: 50%;
    }

    text {
      // width: 160px;
      text-align: left;
    }

    i {
      width: 60px;
      text-align: center;
    }

    margin-bottom: 1vh;
  }
}

.el-submenu__icon-arrow {
  margin-top: -12px;
}

.el-menu-item,
/deep/.el-submenu__title {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 16px;

  i {
    color: #fff;
    margin-right: 10px;
    font-size: 20px;
  }
}

.el-menu {
  padding-top: 40px;

  .logo {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: 20px;
    margin-top: -12px;
    margin-bottom: 20px;
    color: #fff;

    img {
      width: 30px;
      height: 30px;
    }

    span {
      margin-left: 10px;
      font-size: 20px;
      height: 30px;
      line-height: 30px;
    }
  }
}

/deep/.el-icon-arrow-down {
  font-size: 14px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.index_bg {
  background-color: #fff;
}

.home_page {
  display: flex;
  justify-content: start;
}

.left {
  height: 100.8vh;
  // min-width: 200px;
  background-color: #253964;
  margin: -8px 0 0 0px;
  position: relative;
  // width: 11%;
  left: 0;
  top: 0;

  .el-menu-vertical-demo[data-v-fae5bece]:not(.el-menu--collapse) {
    border-right: #253964;
  }
}
</style>

import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store'
const routes = [
  {
    path:'/login', 
    name:"Login",
    component: Login,
  },
  {
    path: '/home',
    redirect: '/',
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    meta:{
      requiresAuth:true
    },
    children:[
      {
        path: '/index',
        name: 'Index',
        component: () => import(/* webpackChunkName: "index" */ '../views/Index.vue')
      },
      {
        // 设备统计，场地统计，时间的统计
        path: '/equipmentStatis',
        name: 'EquipmentStatis',
        component: () => import(/* webpackChunkName: "equipmentStatis" */ '../views/profit/EquipmentStatis.vue')
      },
      {
        // 设备统计，场地统计，时间的统计
        path: '/taocandimens',
        name: 'Taocandimens',
        component: () => import(/* webpackChunkName: "taocandimens" */ '../views/profit/Taocandimens.vue')
      },
      {
        //账单查询
        path: '/billQuery',
        name: 'BillQuery',
        component: () => import(/* webpackChunkName: "billQuery" */ '../views/profit/BillQuery.vue')
      },
      {
        //设备管理
        path: '/equipmentManger',
        name: 'EquipmentManger',
        component: () => import(/* webpackChunkName: "equipmentManger" */ '../views/equipmentManger/EquipmentManger.vue')
      },
      {
        //设备端口
        path: '/equipmentports',
        name: 'Equipmentports',
        component: () => import(/* webpackChunkName: "equipmentports" */ '../views/equipmentManger/Equipmentports.vue')
      },
      {
        //设备故障
        path: '/equipmentGuzh',
        name: 'EquipmentGuzh',
        component: () => import(/* webpackChunkName: "equipmentGuzh" */ '../views/equipmentManger/EquipmentGuzh.vue')
      },
      {
        //卡管理
        path: '/cartManger',
        name: 'CartManger',
        component: () => import(/* webpackChunkName: "cartManger" */ '../views/equipmentManger/CartManger.vue')
      },
      {
        //场地管理
        path: '/areaManger',
        name: 'AreaManger',
        component: () => import(/* webpackChunkName: "areaManger" */ '../views/aearManger/AreaManger.vue')
      },
      {
        //场地管理
        path: '/changStatis',
        name: 'ChangStatis',
        component: () => import(/* webpackChunkName: "changStatis" */ '../views/profit/ChangStatis.vue')
      },
      {
        //管理员设置
        path: '/areaAdmin',
        name: 'AreaAdmin',
        component: () => import(/* webpackChunkName: "areaAdmin" */ '../views/aearManger/AreaAdmin.vue')
      },
      {
        //用户管理
        path: '/userManger',
        name: 'UserManger',
        component: () => import(/* webpackChunkName: "userManger" */ '../views/userManger/UserManger.vue')
      },
      {
        //物业管理
        path: '/propertyManger',
        name: 'PropertyManger',
        component: () => import(/* webpackChunkName: "propertyManger" */ '../views/PropertyManger.vue')
      },
      {
        //用户审核
        path: '/userexamine',
        name: 'Userexamine',
        component: () => import(/* webpackChunkName: "userexamine" */ '../views/userManger/Userexamine.vue')
      },
      {
        //用户详情
        path: '/userdetail',
        name: 'Userdetail',
        component: () => import(/* webpackChunkName: "userdetail" */ '../views/userManger/Userdetail.vue')
      },
      {
        //套餐管理
        path: '/taocanManger',
        name: 'TaocanManger',
        component: () => import(/* webpackChunkName: "taocanManger" */ '../views/operate/TaocanManger.vue')
      },
      {
        //订单查询
        path: '/orderSearch',
        name: 'OrderSearch',
        component: () => import(/* webpackChunkName: "orderSearch.vue" */ '../views/operate/OrderSearch.vue')
      },
      {
        //订单查询
        path: '/openrecord',
        name: 'openrecord',
        component: () => import(/* webpackChunkName: "openrecord.vue" */ '../views/operate/Openrecord.vue')
      },
      {
        //订单查询
        path: '/orderStatistics',
        name: 'orderStatistics',
        component: () => import(/* webpackChunkName: "openrecord.vue" */ '../views/newCharge/orderStatistics.vue')
      },
    
     
      {
        //交易记录
        path: '/admin',
        name: 'Admin',
        component: () => import(/* webpackChunkName: "admin.vue" */ '../views/Admin.vue')
      },
      {
        //门禁列表
        path: '/accessControlList',
        name: 'AccessControlList',
        component: () => import(/* webpackChunkName: "accessControlList.vue" */ '../views/aearManger/AccessControlList.vue')
      },
      {
        //跳转组
        path: '/jumpgroup',
        name: 'JumpGroup',
        component: () => import(/* webpackChunkName: "accessControlList.vue" */ '../views/JumpGroup.vue')
      },
      {
        //系统配置
        path: '/systemConfigurtion',
        name: 'SystemConfigurtion',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/SystemConfigurtion.vue')
      },
      {
        //提醒短信发送
        path: '/sendMessage',
        name: 'sendMessage',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/sendMessage.vue')
      },
      {
        //订单提醒
        path: '/orderreminder',
        name: 'orderreminder',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/orderreminder.vue')
      },
      {
        //挡位管理
        path: '/gearManager',
        name: 'gearManager',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/newCharge/gearManager.vue')
      },
      {
        //充值记录
        path: '/newchargeList',
        name: 'newchargeList',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/newCharge/newchargeList.vue')
      },
      {
        //充值记录
        path: '/newchargeList',
        name: 'newchargeList',
        component: () => import(/* webpackChunkName: "systemConfigurtion.vue" */ '../views/newCharge/newchargeList.vue')
      },
    ]
  },
  
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

console.log(store,'store')
// 路由拦截
router.beforeEach(async (to, from, next) => {
 store.commit('pagination',true)
 store.commit('paginationpage')
 console.log(99999)
 
  let token=localStorage.getItem('token')
  let role=localStorage.getItem('role')
  if(role==5){
    router.removeRoute('Admin')
    router.removeRoute('SystemConfigurtion')
    router.removeRoute('TaocanManger')
    router.removeRoute('Userexamine')
    router.removeRoute('UserManger')
  }
  //不需要登录的直接放行
  if (to.meta.requiresAuth === false) {
    next()
    //如果页面需要登录，且登录失效，进入登录页面
  } else if (!token && to.path !== '/login') {
    next({
      path: '/login'
    })
  } else {
    next()
  }
})

export default router

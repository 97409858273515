import axios from 'axios'
import router from '../router'
// 创建了单例
const instance = axios.create({
    baseURL: "https://ucd.rongxinh.com",
    // baseURL: "https://tcharge.rongxinh.com",
    timeout: 60000
})
// 请求拦截  (拦截器)
instance.interceptors.request.use(config=>{
    let token=localStorage.getItem('token')
    if(token){
        config.headers['Authorization']=token
    }
    return config;
}, err=>{
    return Promise.reject(err);
})

// 响应拦截
instance.interceptors.response.use(result=>{
    if(result.data.code==401){
      router.push('/login')
    }else{
          return result.data;
    }
}, err=>{
    return Promise.reject(err);
})
// 导出单例
export default instance;
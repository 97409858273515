<template>
  <router-view/>
</template>
<script>
 import { defineComponent, ref } from 'vue'
export default {}
</script>
<style lang="less">
body{
  background-color: #eee;
}

@import "http://at.alicdn.com/t/font_2627961_ykr9g0w8n5e.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
 .el-cascader-panel{
  height: 560px ;
}
</style>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './request/request'
import ElementPlus from 'element-plus';
import Moment from 'moment';
// Vue.prototype.moment=Moment
// app.use(Moment)
import 'element-plus/lib/theme-chalk/index.css';
import 'reset-css';
import QrcodeVue from 'qrcode.vue';
const app = createApp(App)
// Vue.prototype.$echarts = echarts
app.use(store).use(router).use(ElementPlus).use(Moment).mount('#app')
// app

// 引入echarts

//一般都要加个$加到vue的原型链上，方便引用
// app.prototype.$echarts = echarts 
<template>
  <!-- 登录页 -->
  <div class="login">
    <div class="log_box">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item prop="name" style="margin-top:30px">
          <span class="name">充电管理后台</span>
        </el-form-item>
        <el-form-item label="账户" prop="name">
          <el-input type="text" v-model="ruleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { Gologin } from "../request/api";
import { ElMessage } from "element-plus";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      }
    };
    var name = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账户名"));
      }
    };
    return {
      ruleForm: { pass: "", name: "" },
      rules: {
        pass: [{ validator: validatePass, trigger: "blur" }],
        name: [{ validator: name, trigger: "blur" }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      let data = {
        tel: this.ruleForm.name,
        pwd: this.ruleForm.pass
      };
      Gologin(data).then(res => {
        console.log(res.list);
       
          if (res.code == 200) {
            ElMessage({
              message: "恭喜你，登录成功",
              type: "success"
            });
          }else{
              ElMessage.error(res.msg);
          }
          setTimeout(() => {
            localStorage.setItem("token", res.list.token);
            localStorage.setItem('role',res.list.role)
            localStorage.setItem("userinfo", res.list.username);
            localStorage.setItem("mobile", res.list.user_phone);
            this.$router.push("/home");
          },500);
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background-image: url("https://head.rongxinh.com/FoaRAbkjXCd9WG6Jd4NRlNarJw1u");
}
.el-input {
  width: 300px;
}
.name {
  font-size: 25px;
  font-weight: 700;
}
.log_box {
  border-radius: 15px;
  text-align: center;
  background: #fff;
  padding: 30px 80px 30px 0;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>